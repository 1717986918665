@use "../mainScss/GuideLine" as *;
@use "../mainScss/ScreenSize" as *;


.MainPageBodyContainer {
  background-color: map-get($colorSystem , "grayColor2");
}

.BodyCol {
  margin: 0px 2% 0px 2%;
  padding: 2% 0px 2% 0px;
}

.BodyDiv {
  padding: 50px 0px 50px 0px;

}

.BodyDivDark {
  @extend .BodyDiv;
  background-color: map-get($colorSystem , "primaryColor");
  color: map-get($colorSystem , "grayColor");

}

.BodyDivLight {
  @extend .BodyDiv;
  // background-color: map-get($colorSystem , "whiteColor" );
  color: map-get($colorSystem , "primaryColor");

}

.wrapper {
  display: flex;
}

.SideBySideDark {
  @extend .BodyDiv;
  flex: 0 0 50%;
  padding: 50px 100px 50px 50px;
  margin-bottom: 50px;
  background-color: map-get($colorSystem , "primaryColor");
  color: map-get($colorSystem , "grayColor");

}

.SideBySideLight {
  @extend .BodyDiv;
  flex: 1;
  padding: 50px 50px 50px 100px;
  //background-color: map-get($colorSystem , "whiteColor" );
  //color:map-get($colorSystem , "primaryColor" );

}

.BodyText {
  @extend .bodyFontSize;
  white-space: pre-wrap;
}

.BodyTitle {
  @extend .lableFontSize;
  border-bottom: 2px solid map-get($colorSystem , "mainColor");
  padding: 20px 0px 5px 0px;
  display: inline-block;
  font-weight: bold;
  width: 100%;



}

.NewsTitle {
  @extend .BodyTitle;
  display: inline-block;
  font-weight: lighter;
  color: map-get($colorSystem , "primaryColor");
  text-decoration: none;

  &:hover {
    color: map-get($colorSystem , "secondaryColorShade1");
  }
}

.BodyTitleDark {
  @extend .BodyTitle;
  border-bottom: 2px solid map-get($colorSystem , "grayColor");

}

.SVGTitleIcon {
  width: 1px;

}

@media ($minExtraVerySmallDevices) and ($maxExtraSmallDevices) {

  .SVGTitleIcon {
    width: 25px;
  }
}

@media ($minExtraVerySmallDevices) and ($maxLargeDevices) {

  .wrapper {
    display: block;
  }

  .SideBySideDark {
    padding: 20px;
    margin-bottom: 0px;

  }

  .SideBySideLight {

    padding: 0px;
  }

}