@use "./ScreenSize" as *;

$colorSystem: (
    "primaryColor": #061041,
    "mainColor": #09154E,
    "titleColor": #1F4385,
    "linkAndIconColor": #2455BF,
    "secondaryColorShade1": #F6A62E,
    "secondaryColorShade2": #E0802F,
    "successColor": #068a65,
    "errorColor": #CE3426,
    "blackColor": #060b25,
    "disabledGrayColor": #626f8a,
    "whiteColor": #f4f5fc,
    "grayColor2": #f3f7fe,
    "grayColor": #E5E7EB,
    "whitewhite": #ffffff,

);
$typeSystem: (
    //Font color
    "fontColorBlack":map-get($colorSystem , "blackColor"),
    "fontColorWhite":map-get($colorSystem , "grayColor"),
    /*
    "header2ColorDark":map-get($colorSystem , "primaryColor" ),
    "header2ColorLight":map-get($colorSystem , "grayColor" ),
    "bodyColorDark":map-get($colorSystem , "blackColor" ),
    "bodyColorLight":map-get($colorSystem , "grayColor" ),
    */
    //Font style
    "fontWeightBold": 'bold',
    "fontWeightLighter": 'lighter',
    /*
    //Font size large screen
    "headerFontSize1": 90px,
    "headerFontSize2": 35px,
    "lableFontSize": 18px,
    "bodyFontSize": 16px,

    //Font size medium screen
    "headerFontSize1Medium": 60px,
    "headerFontSize2Medium": 25px,
    "lableFontSizeMedium": 15px,
    "bodyFontSizeMedium": 15px,*/

);

.headerFont1 {
    font-size: 30px;
}

.HotLine {
    font-size: 50px;
}

.headerFont2 {
    font-size: 25px;
}
.headerFont3{
    font-size: 18px;
}
.lableFontSize {
    font-size: 20px;
}

.bodyFontSize {
    font-size: 23px;
    // text-align: justify;
    text-justify: inter-word;
}

.detailsFontSize {
    font-size: 13px;
}

@media ($minExtraVerySmallDevices) and ($maxExtraSmallDevices) {
    .headerFont1 {
        font-size: 15px;
    }

    .HotLine {
        font-size: 20px;
    }

    .headerFont2 {
        font-size: 15px;
    }

    .lableFontSize, .headerFont3 {
        font-size: 12px;
    }

    .bodyFontSize {
        font-size: 13px;
    }

}


$boarderWidth: 2px;
$padding1: 10px 15px;
$padding2: 10px 25px;
$marginElement: 10px 0px 10px 0px;