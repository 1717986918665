@use "../mainScss/GuideLine" as *;
@use "../mainScss/ScreenSize" as *;

.sloganCard {
  direction: ltr;

  background: map-get($colorSystem , "primaryColor");
  height: 70px;
  width: 450px;
  right: 20px;
  margin-top: -30px;
  border: $boarderWidth;
  border-style: solid;
  border-color: map-get($colorSystem , "grayColor");
  color: map-get($colorSystem , "grayColor");
  position: absolute;

  &:after,
  &:before {
    content: '';

    border: $boarderWidth;
    border-style: solid;
    border-color: map-get($colorSystem , "grayColor");
    background: map-get($colorSystem , "titleColor");
    z-index: -1;
    position: absolute;
  }

  &:before {
    width: 25px;
    height: 80%;
    transform: translatex(-100%) skewy(310deg);
    top: 13px;
  }
}

.divCardRTLDark {
  right: 0px;
  height: 70px;
  @extend .sloganCard;
  border-color: map-get($colorSystem , "primaryColor");
  margin-top: -52px;
  z-index: 1;

  &:after,
  &:before {

    border-color: map-get($colorSystem , "primaryColor");
    background: map-get($colorSystem , "primaryColor");
  }

  &:before {
    width: 60px;
    top: 33px;
  }
}

.SubPagesHeaderDecoration {
  height: 80px;
  @extend .sloganCard;
  background-color:  map-get($colorSystem , "whiteColor");
  border-color:  map-get($colorSystem , "whiteColor");
  margin-top: -70px;
  z-index: 1;
  width: 50%;
  right: 25%;

  &:after,
  &:before {

    border-color:  map-get($colorSystem , "whiteColor");
    background:  map-get($colorSystem , "whiteColor");
  }

  &:before {
    width: 60px;
    height: 80px;
    top: 33px;
    clip-path: polygon(0 0, 100% 100%, 100% 0%, 0 0, 0 0);

  }

  &:after {
    width: 60px;
    height: 80px;
    top: 33px;
    clip-path: polygon(0 0, 0 100%, 230% 0%, 0 0, 0 0);
    right: 0;
    transform: translatex(100%) skewy(50deg);
  }
}

.divCardRTLLight {
  @extend .divCardRTLDark;
  background:  map-get($colorSystem , "whiteColor");

  border-color:  map-get($colorSystem , "whiteColor");

  &:after,
  &:before {

    border: none;
    background-color:  map-get($colorSystem , "whiteColor");
  }
}

.footerDiv {
  color: map-get($colorSystem , "grayColor");
  border: $boarderWidth;
  border-style: solid;
  border-color: map-get($colorSystem , "grayColor");
  background: map-get($colorSystem , "primaryColor");
  padding: 50px 0px 50px 0px;

}

.sloganCardBody {

  @extend .headerFont2;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 450px;
  padding-top: 10px;
}

.sloganCardBodyLight {
  @extend .bodyFontSize;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  color: map-get($colorSystem , "primaryColor");
  ;

}

.FooterSVG {
  width: 35px;
  height: 35px;
  margin: 10px -5px 0px 7px;
}

.FooterCol {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.FooterLogo {
  width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.centerTitle {
  color: map-get($colorSystem , "grayColor");
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 1px;
  width: 100%;
  padding: 10px;
  // font-size: 45px;
  font-weight: 100;
}

.HeaderSubPage {
  @extend .headerFont1;
  @extend .centerTitle;
  padding: 20vmin 0px 0px 0;
}

.phoneNumber {
  @extend .HotLine;
  @extend .centerTitle;
}

.shortIcon {
  @extend .centerTitle;
  padding: 20% 20% 0px 20%;
  width: 100%;
  height: auto;
  fill: map-get($colorSystem , "grayColor");

  &:hover {
    fill: map-get($colorSystem , "secondaryColorShade1");
  }
}

.shortIconText {
  @extend .lableFontSize;
  @extend .centerTitle;
  padding-bottom: 30px;
  width: 100%;

}

.CopyRight {
  @extend .detailsFontSize;
  color: map-get($colorSystem , "grayColor");
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 2px;
  width: 100%;

}

.SVGIconDiv {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 2px;
  width: 100%;
  margin-top: -30px;
}

.SocialIconsWhite {
  letter-spacing: 2px;
  padding-top: -100px;
  padding: 7px;

}

.FooterLable {
  @extend .lableFontSize;
  border-bottom: 2px solid map-get($colorSystem , "grayColor");
  padding: 0px 0px 5px 0px;
  display: inline-block;
  font-weight: bold;
  width: 100%;
}

.FooterInfo {
  @extend .detailsFontSize;
  border-bottom: 0px;
  display: inline-block;
  font-weight: lighter;
  color: map-get($colorSystem , "grayColor");
  text-decoration: none;

  &:hover {
    color: map-get($colorSystem , "secondaryColorShade1");
  }
}

.TitleLink {
  @extend .bodyFontSize;
  border-bottom: 0px;
  // display: inline-block;
  font-weight: lighter;
  color: map-get($colorSystem , "primaryColor");
  text-decoration: none;

  &:hover {
    color: map-get($colorSystem , "secondaryColorShade1");
  }
}

.TitleLinkDark {
  @extend .TitleLink;
  color: map-get($colorSystem , "grayColor");

}


@media ($minExtraSmallDevices) and ($maxExtraSmallDevices) {
  .sloganCard {
    height: 45px;
    width: 270px;
    right: 20px;
    margin-top: -30px;

  }

  .sloganCardBodyLight {
    right: 0px;
  }

  .SubPagesHeaderDecoration {
    margin-top: -40px;
    width: 50%;
    right: 25%;

    &:before {
      clip-path: polygon(0 0, 100% 100%, 100% 0%, 0 0, 0 0);

    }

    &:after {
      top: 33px;
      clip-path: polygon(0 0, 0 100%, 130% 0%, 0 0, 0 0);
    }

  }

  .divCardRTLDark {
    right: 0px;
  }

  .sloganCardBody {
    padding-top: 5px;
    width: 270px;
  }

  .FooterSVG {
    width: 25px;
    height: 25px;
    margin: 8px -10px 0px 5px;
  }

  .footerDiv {
    padding-top: 15px;

  }

  .FooterLogo {
    width: 150px;
  }
}

@media ($minExtraVerySmallDevices) and ($maxExtraVerySmallDevices) {
  .sloganCard {
    height: 45px;
    width: 210px;
    right: 20px;
    margin-top: -30px;

  }

  .sloganCardBodyLight {
    right: 0px;
  }

  .SubPagesHeaderDecoration {
    margin-top: -40px;

    width: 50%;
    right: 25%;

    &:before {
      clip-path: polygon(0 0, 100% 100%, 100% 0%, 0 0, 0 0);

    }

    &:after {
      top: 33px;
      clip-path: polygon(0 0, 0 100%, 130% 0%, 0 0, 0 0);
    }
  }

  .divCardRTLDark {
    right: 0px;
  }

  .sloganCardBody {
    padding-top: 0px;
    width: 200px;
  }

  .FooterSVG {
    width: 20px;
    margin: 5px -10px 0px 5px;
  }

  .HeaderSubPage {
    padding: 0vmin 0px 0px 0;
  }
}